<template>
  <x-tooltip
    placement="auto"
    :delay="100"
  >
    <x-icon
      name="info"
      class="i-tooltip"
    />
    <template
      #popper
    >
      <div
        class="i-tooltip__content"
        v-html="content"
      />
    </template>
  </x-tooltip>
</template>

<script>
export default {
  name: 'ITooltip',

  props: {
    content: {
      type: String,
      required: true
    }
  }
}
</script>

<style lang="stylus" scoped>
.v-popper
  display inline-flex
  align-items center
  justify-content center

.i-tooltip
  width 16px
  height 16px
  color #CACAD3

  &__content {
    max-width 410px
  }
</style>
