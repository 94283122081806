<template>
  <v-popover
    :boundaries-element="bindTo"
    :placement="placement"
  >
    <x-btn
      ghost
      type="button"
      size="xs"
      :icon="$slots.default ? undefined : 'dots-vertical'"
      class="grid-action-menu"
    >
      <slot />
    </x-btn>

    <template slot="popover">
      <ul class="grid-action-menu__list">
        <li
          v-for="(action, index) in actions"
          :key="index"
          class="grid-action-menu__list-item"
        >
          <x-btn
            v-close-popover="isClosed"
            type="button"
            block
            ghost
            color="gray"
            :prepend="action.iconName"
            class="grid-action-menu__btn"
            @click="onActionCb(action)"
          >
            <span>
              {{ action.label }}
            </span>
          </x-btn>
        </li>
      </ul>
    </template>
  </v-popover>
</template>

<script>
export default {
  props: {
    actions: {
      type: Array,
      required: true
    },
    row: {
      type: Object,
      default: () => ({})
    },
    bindTo: {
      type: String,
      default: 'window'
    },
    placement: {
      type: String,
      default: ''
    }
  },

  data () {
    return {
      isClosed: true
    }
  },

  methods: {
    async onActionCb (action) {
      this.isClosed = true

      if (action.cb && typeof action.cb === 'function') {
        action.cb(this.row)
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
  .grid-action-menu
    &__list
      background #fdfdfd
      border-radius 4px
      box-shadow 0px 6px 16px rgba(5, 2, 55, .12)

    &__list-item
      list-style none
      font-size $font-sizes.text

    &__btn
      >>> .x-btn__content
        justify-content flex-start
</style>
